import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Thread from "./Thread";
import AutoScrollable from "../global/AutoScrollable";
import { LoginContext } from "./Login";
import ReplyForm from "./ReplyForm";
import UserBlock from "./UserBlock";
import { getCookie, setCookie } from "../global/Global";
import Search from "./Search";
import useDidUpdateEffect from "../global/DidUpdateEffect";

const Users = () => {
  const [users, setUsers] = useState([]);
  let mode = getCookie("usersmode");
  if (typeof mode == "undefined" || mode === null) {
    mode = 2;
  }
  const [usersMode, setUsersMode] = useState(mode);
  const [usersModePeriod, setUsersModePeriod] = useState(
    getCookie("usersmodeperiod") || 3,
  );
  const { user: myUser } = useContext(LoginContext);
  const [searchQuery, setSearchQuery] = useState("");

  useDidUpdateEffect(() => {
    logT("search", "search query", searchQuery);
    fetchData(true);
  }, [searchQuery]);

  const fetchData = async (full = false) => {
    let index = (users && users.length) || 0;
    let limit = 50;
    if (full) {
      index = 0;
      limit = Math.max(50, users.length);
    }
    const token = getCookie("token") || "";
    const userid = parseInt(getCookie("userid")) || 0;
    let mode = getCookie("usersmode");
    if (typeof mode == "undefined" || mode === null) {
      mode = 2;
    }
    const usermodePeriod = parseInt(getCookie("usersmodeperiod") || 3);
    const data = await window.TALKVIOAPI("users", {
      index,
      limit,
      token,
      userid,
      popular: mode == 0,
      activity: mode == 1,
      byKarma: mode == 3,
      byPosts: mode == 4,
      period: usermodePeriod,
      search: searchQuery?.length > 0 ? searchQuery : undefined,
    });
    if (!full) setUsers((users || []).concat(data.users));
    else setUsers(data.users);

    document.title = `${__("Users list")} - Talkvio`;
  };

  window.TALKVIO_ON(
    "subscription",
    (sub) => {
      for (const u of users) {
        if (u.userid == sub.targetuserid) {
          fetchData(true);
          break;
        }
      }
    },
    "users",
  );

  window.TALKVIO_ON(
    "userRegistered",
    (data) => {
      logT(
        "userRegistered",
        "new user",
        data.userid,
        "username",
        data.username,
      );
      if (usersMode == 2) {
        logT("userRegistered", "triggering fetchData");
        fetchData(true);
      }
    },
    "users",
  );

  window.TALKVIO_ON(
    "removeAccount",
    (data) => {
      logT("removeAccount", "user removed", data.userid);
      fetchData(true);
    },
    "users",
  );

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="usersViewContainer">
      <div className="mainTitle">
        {usersMode == 0 || usersMode == 1 ? (
          <div className="categorySwitcher">
            <div>{__("Period")}:</div>
            <select
              value={usersModePeriod}
              onChange={(event) => {
                const period = parseInt(event.target.value);
                setCookie("usersmodeperiod", period, 30);
                setUsersModePeriod(period);
                fetchData(true);
              }}
            >
              <option value="1">{__("One day")}</option>
              <option value="3">3 {__("Last Days")}</option>
              <option value="7">{__("Week")}</option>
              <option value="30">{__("Month")}</option>
              <option value="90">3 {__("Months")}</option>
            </select>
          </div>
        ) : null}
        <div className="titleText">{__("Users")}</div>
        {true ? (
          <div className="direction">
            <div>{__("Filter")}:</div>
            <select
              value={usersMode}
              onChange={(event) => {
                const mode = parseInt(event.target.value);
                setCookie("usersmode", mode, 30);
                setUsersMode(mode);
                fetchData(true);
              }}
            >
              <option value="0">{__("Popular")}</option>
              <option value="1">{__("Active")}</option>
              <option value="2">{__("New users")}</option>
              <option value="3">{__("By karma")}</option>
              <option value="4">{__("By posts")}</option>
            </select>
          </div>
        ) : null}
      </div>
      <div className="searchBox">
        <Search
          onSeach={(text) => {
            setSearchQuery(text);
          }}
          noClearInput={true}
        />
      </div>
      <AutoScrollable onBottomScroll={() => fetchData()}>
        <ul className="userslist">
          {users.map((user) => (
            <UserBlock user={user} myUser={myUser} key={user.userid} />
          ))}
        </ul>
      </AutoScrollable>
    </div>
  );
};
export default Users;
